























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Combination, Preset} from '@/types';
import {PresetService} from '@/api';
import UBlock from '@/ui-lib/block/Block.vue';
import UTable, {TableField} from '@/ui-lib/table/Table.vue';
import UButton from '@/ui-lib/button/Button.vue';

@Component<PresetItemCombinationsView>({
    components: {
        UBlock,
        UTable,
        UButton,
    },

    metaInfo: {
        title: 'Комбинации',
    },

    async beforeRouteEnter(to, from, next) {
        try {
            const presetId = parseInt(to.params.id, 10);
            const combinations = await PresetService.combinations(presetId);

            next((vm) => {
                vm.combinations = combinations;
            });
        } catch (e) {
            next(e);
        }
    },
})
export default class PresetItemCombinationsView extends Vue {
    @Prop({required: true})
    private preset!: Preset;

    private combinations: Combination[] = [];

    private get fields(): TableField[] {
        return [
            {
                name: 'id',
                label: 'ID',
            },

            {
                name: 'name',
                label: 'Комбинация',
            },

            {
                name: 'compilationCount',
                label: 'Всего подборок',
            },

            {
                name: 'trackCount',
                label: 'Всего треков',
            },
        ];
    }

    private name(item: Combination): string {
        return `Динамика: ${item.dynamics} | Акустика: ${item.acoustics}`;
    }
}
